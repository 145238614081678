<template>
    <div>
        <v-container fluid grid-list-md wrap>
            <v-layout row wrap v-show="display">
                <v-flex xs12 sm12 md12 lg12>
                    <div class="mr-3 hidden-sm-and-down" style="text-align:center">
                        <img src=".././assets/500.svg" alt="">
                    </div>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 wrap>
                    <h2 style="text-align:center" class="headline">Ni ustreznih rezultatov za iskani niz <strong>{{ search }}</strong>.</h2>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import store from '.././store'
export default {
    name: 'no-results',
    props: ['data', 'naziv'],
    data: () => ({

    }),

    computed: {
        display() {
            if(this.$store.getters.search != null && this.$store.getters.search.trim() != '' && this.data.length < 1) {
                return true;
            }

            return false;
        },

        search() {
            return this.$store.getters.search
        }
    },

    methods: {

    },

    created() {
        //console.log(this.$route.name)
    },

    mounted() {

    }
}
</script>

<style scoped>
    h1 {
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
        color: #252932;
        text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;    
    }
</style>


